// TODO: Remove after form refactor
import { gql } from '@apollo/client';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import shortId from 'shortid';
import { GraphQLClient } from 'graphql-request';
import oldClient from 'services/Client';
import logger from 'services/Logger';
import { getYear } from 'helpers/RelativeTimeFormatter';

const client = new GraphQLClient('/import/graphql');

const FETCH_DELIVERY_MODES = gql`
  query fetchDeliveryModes {
    deliveryOptionsList {
      items {
        id
        mode
      }
    }
  }
`;

const fetchDeliveryMode = async mode => {
  const response = await oldClient.query({ query: FETCH_DELIVERY_MODES });
  const modes = response.data.deliveryOptionsList.items || [];

  return modes.find(deliveryMode => deliveryMode.mode.toLowerCase() === mode).id;
};

const FETCH_WORK_ARRANGEMENT = gql`
  query fetchArrangement {
    workArrangementsList {
      items {
        id
        arrangement
      }
    }
  }
`;

const fetchArrangement = async () => {
  const response = await oldClient.query({ query: FETCH_WORK_ARRANGEMENT });
  const modes = response.data.workArrangementsList.items || [];

  return modes.find(mode => mode.arrangement === 'FULL_TIME').id;
};

const FETCH_CLEARANCES = gql`
  query fetchClearances {
    clearanceLevelsList {
      items {
        id
        name
      }
    }
  }
`;

const fetchClearance = async clearance => {
  const response = await oldClient.query({ query: FETCH_CLEARANCES });
  const clearances = response.data.clearanceLevelsList.items || [];
  const foundClearance = clearances.find(item => item.name === clearance);
  const noClearanceId = clearances.find(item => item.name === 'No Clearance').id;

  return foundClearance ? foundClearance.id : noClearanceId;
};

const FETCH_LOCATION = gql`
  query fetchLocation($city: String) {
    locationsList(filter: { city: { equals: $city } }) {
      items {
        id
      }
    }
  }
`;

const fetchLocation = async city => {
  const response = await oldClient.query({ query: FETCH_LOCATION, variables: { city } });

  if (response.data.locationsList.items[0]) {
    return response.data.locationsList.items[0].id;
  }

  return null;
};

const FETCH_EXPERIENCE_LEVELS = gql`
  query fetchExperienceLevels {
    experienceLevelsList {
      items {
        id
        title
      }
    }
  }
`;

const fetchExperienceLevel = async level => {
  const response = await oldClient.query({ query: FETCH_EXPERIENCE_LEVELS });
  const levels = response.data.experienceLevelsList.items;
  const foundLevel = levels.find(item => item.title.toLowerCase() === level);

  return foundLevel ? foundLevel.id : null;
};

const fetchDocument = async ({ downloadLink, filename }) => {
  const response = await fetch(downloadLink, {
    method: 'GET',
  });

  if (response.ok) {
    const blob = await response.blob();

    return new File([blob], filename, { type: 'application/pdf' });
  }

  return null;
};

const FETCH_RECORD = /* GraphQL */ `
  query fetchImportRecord($id: ID!) {
    record(id: $id) {
      id
      filename
      downloadLink
      parsedData
    }
  }
`;

const fetchImportData = async id => {
  const response = await client.request(FETCH_RECORD, { id });
  const data = response.record.parsedData;
  const [
    arrangement,
    deliveryModeId,
    clearanceId,
    locationId,
    experienceLevelId,
    documentFile,
  ] = await Promise.all([
    fetchArrangement(),
    fetchDeliveryMode(data.workplaceType),
    fetchClearance(data.clearance),
    data.location ? fetchLocation(data.location.city) : '',
    data.experienceLevel ? fetchExperienceLevel(data.experienceLevel) : '',
    fetchDocument({
      downloadLink: response.record.downloadLink,
      filename: response.record.filename,
    }),
  ]);

  const parsedData = {
    rfq: {
      label: data.rfq,
      value: 'createNew',
    },
    title: {
      value: data.title,
    },
    start: data.startDate || undefined,
    end: data.endDate || undefined,
    evaluationMethod: {
      label: 'Lowest Price',
      value: 'LOWEST_PRICE',
    },
    arrangement: {
      key: 'FULL_TIME',
      label: 'Full-time',
      value: arrangement,
    },
    maximumCandidatesAllowed: {
      error: false,
      value: 3,
    },
    mode: {
      key: data.workplaceType.toUpperCase(),
      label: capitalize(data.workplaceType),
      value: deliveryModeId,
    },
    clearance: {
      label: data.clearance,
      value: clearanceId,
    },
    hours: {
      value: data.levelOfEffort,
    },
    skills: {
      value: data.skills.map(skill => ({
        id: shortId(),
        label: 'Required',
        value: 'REQUIRED',
        description: skill,
      })),
    },
    activities: {
      value: data.requirements.map(requirement => ({ id: shortId(), description: requirement })),
    },
  };

  if (documentFile) {
    parsedData.documents = {
      value: [
        {
          id,
          file: documentFile,
          size: documentFile.size,
          name: documentFile.name,
        },
      ],
    };
  }

  if (data.location && !locationId) {
    logger.error(`Missing location for city "${data.location.city}"`, data.location);
  }

  if (data.location && locationId) {
    parsedData.location = {
      label: `${data.location.city}, ${data.location.country.name}`,
      value: locationId,
    };
  }

  if (data.experienceLevel && experienceLevelId) {
    parsedData.level = {
      value: {
        id: experienceLevelId,
      },
    };
  }

  // deprecated
  if (data.rate) {
    const startYear = getYear(data.startDate);

    parsedData.nteRates = {
      value: [{ error: false, highlighted: false, year: startYear, value: data.rate }],
    };
  }

  if (data.rates) {
    parsedData.nteRates = {
      value: data.rates.map(rate => ({
        error: false,
        highlighted: false,
        year: rate.year,
        value: rate.value,
      })),
    };
  }

  if (data.dueDate) {
    const isMonday = moment(data.dueDate).isoWeekday() === 1;
    // set the due date a day before at midnight
    const parsedDueDate = moment(data.dueDate)
      // if due date is on Monday, then set due date on Friday instead of day before
      .subtract(isMonday ? 3 : 1, 'day')
      .set({ hour: 23, minute: 55, second: 0 });

    parsedData.dueOn = parsedDueDate;
  }

  return parsedData;
};

export default fetchImportData;
